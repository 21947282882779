.settings > span > div:first-child {
    height: 30vh;
    position: relative;    
    background-position: center !important;
}
.settings div:first-child ion-title, .settings div:first-child ion-input {
    position: absolute;
    bottom: 10%;
    font-size: 3em;
}
.settings div:first-child ion-input{
    width: 70%;
    margin-left: 10px;
}
.settings .user-edit{
    border-bottom: 1px solid var(--ion-color-dark);
}
.settings ion-textarea.user-edit{
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 10px;
}
.settings ion-icon{
    margin-right: 0px;
}
.settings span:first-child h3 {
    margin-left: 20px;
}
.settings .bgGreen::part(native){
    background-color: green;
}
#prefs-label{
    font-size: 2.5em;
    margin: 20px;
}
#prefs-label span{
    font-size: 0.5em;
}
.prefs{
    width: auto;
    margin-left: 20px;
    margin-right: 30px;
}
.prefs:hover{
    background-color: rgba(50, 50, 50, 0.1);
    filter: brightness(150%);
}
.settings .prefs ion-icon{
    margin-right: 20px;
}
.pref-logout{
    color: #ef473a;
}
.settings .prefs.pref-logout:hover {
    background-color: #ef473a;
    filter: brightness(75%);
}
.settings .prefs.pref-logout ion-icon{
    color: #ef473a;
}
.settings .prefs.pref-logout:hover ion-icon{
    color: white;
}
.trasparentBGshadow::part(native){
    background: transparent;
}
.trasparentBGshadow {
    background-position: center !important;
    background: transparent;
}
.circle-picker{
    margin-left: 36px;
}
ion-avatar{
    margin-right: 30px;
}
ion-icon{
    margin-right: 20px;
}
ion-fab-button ion-icon{
    margin-right: 0;
}

#homepage{
    display: flex;
    flex-flow: row wrap;
    padding-top: 0;
    padding-bottom: 30px;
}
#homepage .rw-container{
    width: 100%;
}
/*#homepage .hm-full{
    flex-basis: 100%
}

#homepage .hm-half{
    flex-basis: 50%
}*/

@media screen and (max-width: 500px) {
    
    #homepage .hm-half{
        flex-basis: 100%
    }
}
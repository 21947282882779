.cibo ion-avatar{
    margin-right: 30px;
    --border-radius: 5px;
    width: 100px;
    height: 100px;
}
.details ion-img{
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.details ion-title{
    font-size: 32px;
    margin-top: 10px;
}
.details ion-text{
    margin-left: 20px;
}
.details ion-button{
    margin-right: auto;
    text-align: center;
    margin-top: 20px;
}
.my-custom-class ion-datetime{
    background-color: #999;
    height: 30px;
    margin-top: 15px;
    color: black;
}
.alert-message.sc-ion-alert-md{
    padding-bottom: 10px;
}
.alert-button-group.sc-ion-alert-md{
    padding-top: 0;
}
ion-avatar{
    margin-right: 30px;
}
ion-icon{
    margin-right: 20px;
}
.dests ion-item{
    height: 70px;
}
.dests ion-item > *{
    font-size: 50px;
}
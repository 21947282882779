.settings > span > div:first-child {
    height: 30vh;
    position: relative;    
    background-position: center !important;
}
.settings div:first-child ion-title, .settings div:first-child ion-input {
    position: absolute;
    bottom: 10%;
    font-size: 3em;
}
.settings div:first-child ion-input{
    width: 70%;
    margin-left: 10px;
}
.settings .group-edit{
    border-bottom: 1px solid white;
}
.settings ion-textarea.group-edit{
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 10px;
}
.settings ion-icon{
    margin-right: 0px;
}
.settings .prefs ion-icon[slot="end"]{
    margin-right: 0;
}
/*
.settings #edit-group{
   position: absolute;
   height: auto;
   bottom: 10%;
   font-size: 1.5em;
   right: 0px;
   color: white;
}
.settings #edit-group:hover::part(native)::after {
    background-color: transparent ;
}
.settings #edit-group ion-icon{
    width: 35px;
    height: 35px;
    padding: 11px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 100px 100px;
}
.settings #edit-group:hover ion-icon{
    background-color: rgba(255, 255, 255, 0.4);
}*/
.settings span:first-child h3 {
    margin-left: 20px;
}
.settings .bgGreen::part(native){
    background-color: green;
}
.settings .sorry-text{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
#members-label{
    font-size: 2.5em;
    margin: 20px;
}
#members-label span{
    font-size: 0.5em;
}
.members, .setting-item{
    width: auto;
    margin-left: 20px;
    margin-right: 30px;
}
.members:hover, .setting-item:hover{
    background-color: rgba(50, 50, 50, 0.1);
    filter: brightness(150%);
}
.trasparentBGshadow::part(native){
    background: transparent;
}
.trasparentBGshadow {
    background-position: center !important;
    background: transparent;
}
.red{
    color: #ef473a;
}
.blue{
    color: #3a82ef;
}
.bg-blue{
    background-color: #3a82ef;
}
.bg-blue.blue, .bg-blue .blue{
    color: white;
}
ion-chip{
    padding: 25px;
    border-radius: 50px;
    line-height: 15px;
    font-size: 1em;
    clear: both;
}
 
.mio{
    margin-right: 10px;
    margin-left: auto;
}

.tuo{
    margin-left: 10px;
}
.chat-list{
  max-width: 80%;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px;
  background-color: #2f2f2f;
  border-radius: 25px;
  clear: both;  
  position: sticky;
}

.chat-list p{ 
  overflow-wrap: break-word;
  white-space: initial;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 8px;
  margin-bottom: 8px
}

.chat-list.chat-image{
  max-width: 80%;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 20px;
}
.chat-list.chat-image ion-img, .chat-list.chat-image img{
  max-width: 100%;
}
.chat-list.chat-image ion-img::part(image), .chat-list.chat-image img{
  border-radius: 20px 20px 0px 0px;
}
.chat-list.chat-image.no-text img{
  margin-bottom: -4px;
}
.chat-list.chat-image.no-text ion-img::part(image), .chat-list.chat-image.no-text img{
  border-radius: 20px 20px 20px 20px;
}
.chat-list.chat-image p{
  margin-left: 15px;
}
.chat-list.chat-image.no-text p.text{
  display: none;
}
.chat-list pre{
  font-family: var(--ion-font-family, inherit);
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.chat-list i {
  position: absolute;
  font-size: xx-small;
  top: 10px;
  display: block;
}

.chat-list.mio{
  float: right;
  background-color: #e42112;
}
.chat-list.mio p{
  color: white;
}
.chat-list.mio i{
  left: -30px;
}
.chat-list.tuo{
  float: left;
  border-color: #e42112;
  /* background-color: #2f2f2f; */
  background-color: var(--ion-color-light);
}
/*.chat-list.tuo p{
  color: white;
}*/
.chat-list.tuo i{
  right: -30px;
}
.chat-list.chat-image.tuo i{
  right: 10px;
}
.chat-list.tuo .chat-sender{
  color: #e42112;
  font-weight: bold;
}
.chat-list-separator{
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: lightgray;
  color: black;
  border-radius: 25px;
  clear: both;
  margin-top: 0px;
  margin-bottom: 0px
}
.chat-list-separator p{
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px
}


.chat-btn{
  font-size: 25px;
  margin-right: 0;
}
.img-prev{
  max-width: 50px;
  max-height: 45px;
  position: relative;
}
#del-img{
  position: absolute;
  left: 12px;
  bottom: 3px;
}
.allowEvent{
  pointer-events: all!important;
}

/* Loading page */
#chat-loading{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--ion-background-color, #fff);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}
.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
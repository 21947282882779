ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  /*--padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;*/
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-list:first-child{
  padding-top: 0;
}

ion-menu.md ion-list:first-child div {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 40px;
  padding-bottom: 0px;

}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  /*color: #757575;*/
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
  font-size: 24px;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
} 

.menu-user{
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.usersetting-icon {
  font-size: 28px;
  float: right;
  margin-top: -20px;
  color: #616e7e;
}
.add-button{
  margin-top: 0px;
  margin-bottom: 0px;
  height: auto;
}
.menu, .group-list{
  transition: all ease 0.2s;
}
.menu-selected > ion-item, .menu-selected ion-list{
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.group-list{
  max-height: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  overflow: hidden;
}
.group-list-open{
  max-height: 1000px;
  height: auto;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  transition: all ease-in-out 0.4s;
}
ion-menu ion-item ion-icon.hover-red{
  margin-right: 12px;
}
ion-menu ion-item ion-icon.hover-red:hover{
  color: #e42112;
}

ion-menu .reorder-enabled ion-reorder ion-item{
  background-color: rgba(255, 255, 255, 0.1);
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 15px !important;
  animation: shake 1s;
  animation-iteration-count: 1; 
}
ion-menu .reorder-enabled ion-reorder:nth-of-type(1) ion-item{
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 20px !important;
  animation-iteration-count: 0; 
}

.trasparentBGshadow::part(native){
  background: transparent;
}
.trasparentBGshadow {
  background-position: center !important;
  background: transparent;
}
#labels-list ion-list ion-menu-toggle ion-item{
  margin-left: 20px;
  transition: all 0.5s ease;
}

@media screen and (prefers-color-scheme: light) {  
  .menu-selected > ion-item, .menu-selected ion-list{
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
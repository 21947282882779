.loginPage ion-toolbar{
        --border-style: none;
        --border-color: transparent;
        --padding-top: 1rem;
        --padding-bottom: 1rem;
        --padding-start: 1rem;
        --padding-end: 1rem;
}

.headingText h5{
        margin-top: 0.2rem;
        color: #ef473a;
}

.field:not(:last-child) {

    margin-bottom: 1rem !important;
}

.field ion-label{

        padding-left: 0.2rem;
        padding-right: 0.5rem;
        color: #ef473a;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;



}
.field p {

            color: rgb(236, 149, 35);
}

.customInput {

    --background: var(--ion-color-light);
    --padding-bottom: 1rem;
    --padding-top: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
    border-radius: 10px;
    margin-top: 0.25rem;
    transition: all 0.2s linear;
}

.full-width{
        width: 100%;
        height: 100%;
        object-fit: cover;
}
#album-selector{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 50px;
    background-color: grey;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
#album-selector::-webkit-scrollbar{
    display: none;
}
#album-selector #album-slider{
    width: 200vw;
}
#album-selector article{
    display: block;
    float: left;
    margin-left: 20px;
    font-weight: bold;
    text-align: center;
    height: 100%;
}
#album-selector article p{
    display: inline-block;
    margin-top: 15px;
    padding-bottom: 9px;
    border-bottom: 5px solid red;
}
#UploadingStatus{
    position: fixed;
    bottom: 100px;
    right: 10px;
    background-color: var(--ion-color-light);
    padding: 10px;
    border-radius: 3px;
}
#section-container{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}
article.section{
    width: 145px;
    height: 150px;
    background-color: transparent;
    border-radius: 10px;
    position: relative;
    color: white;
}
article.section:hover{
    opacity: 0.8;
    /*background-color: rgba(55, 55, 55, 0.8);*/
}
article.section .section-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    opacity: 1;
    background-color: rgb(55, 55, 55);
    z-index: -2;
}
article.section h4{
    margin-top: 35px;
    margin-left: 15px;
}
article.section p{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    margin-bottom: 0;
}
article.section p.installed{
    margin-top: 6px;
    font-size: 10px;
}
article.section ion-icon{
    font-size: 35px;
    position: absolute;
    right: 10px;
    top: 10px;
    margin-right: 0;
    /*    Big Background
    font-size: 85px;
    color: black;
    opacity: 0.3;
    z-index: -1;
    color: var(--background);
    */
}
.addon-details .ion-page{
    display: block;
}
.addon-details .ion-page ion-card-header{
    margin-top: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.addon-details .ion-page ion-title{
    padding-left: 0;
    float: left;
}
.addon-details .ion-page ion-icon{
    font-size: 24px;
}
.addon-details .ion-page ion-button{
    float: right;
    margin-top: 0;
}
.addon-details .ion-page hr{
    clear: both;
}
.addon-details .ion-page p{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.modal-close{
    width: 90%;
    position: absolute;
    bottom: 10px;
    left: 5%;
    right: 5%;
}

@media ( 300px <= width <= 450px){
    article.section{
        width: 165px;
        height: 160px;
    }
    article.section h4{
        margin-top: 55px;
    }
    article.section ion-icon{
        font-size: 50px;
    }
}